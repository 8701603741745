import { Button, Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { get_api_caller, login } from './api';
import { GoogleLogin } from './ext/GoogleAuthorize';

const defaultUserContext = {
  authenticated: false,
  alephToken: null,
  aleph_user: null,
};

const GOOGLE_CLIENT_ID = '771621236425-st090viekqcpepg70u1kcrmcmn4ch13h.apps.googleusercontent.com';

const localToken = localStorage.getItem('aleph-token');
const fakeLocalToken = localStorage.getItem('fake-aleph-token');
const authenticated = !_.isEmpty(localToken);
const localState = _.merge({}, defaultUserContext, {
  alephToken: fakeLocalToken || localToken,
  authenticated: authenticated,
});

const userContext = React.createContext(localState);

const LoginButton = ({
  userStateDispatcher,
  theme = 'filled_blue',
  size = 'large',
  text = 'signin_with',
  shape = 'pill',
  width = 250,
  type = 'standard',
}) => (
  <GoogleLogin
    clientId={GOOGLE_CLIENT_ID}
    onSuccess={(response) => successfulLogin(userStateDispatcher, response)}
    theme={theme}
    size={size}
    text={text}
    shape={shape}
    width={width}
    type={type}
  />
);
const killFake = () => {
  localStorage.removeItem('fake-aleph-token');
  window.location.reload();
};

const ImpersonateForm = ({ userStateDispatcher, source = 'app' }) => {
  const [email, set_email] = useState('');
  const [error, set_error] = useState('');
  const api = useAPI();
  const history = useHistory();

  const handle_submit = async (e) => {
    e.preventDefault();
    const aleph_other_login = await api('admin/login-as', { method: 'POST', body: JSON.stringify({ email }) });
    if (aleph_other_login.success === true) {
      userStateDispatcher({
        type: 'loginUser',
        payload: {
          authenticated: true,
          alephToken: aleph_other_login.token,
        },
      });
      localStorage.setItem('fake-aleph-token', aleph_other_login.token);
      set_error('');
      source === 'app' ? history.goBack() : window.location.reload();
    } else {
      set_error(JSON.stringify(aleph_other_login, null, 2));
    }
  };
  const handle_email = (e) => set_email(e.target.value);
  return (
    <>
      {source === 'app' && (
        <Grid container justifyContent="center">
          <form id="login-as-form" onSubmit={handle_submit}>
            <TextField
              id="email"
              type="email"
              label="Fake User Email"
              value={email}
              onChange={handle_email}
              required
              fullWidth
            />
            <Button type="submit" form="login-as-form" color="primary" variant="contained" style={{ marginTop: '1em' }}>
              Login
            </Button>
          </form>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      )}
      {source === 'extension' && (
        <>
          <form id="login-as-form" onSubmit={handle_submit}>
            <TextField
              id="email"
              type="email"
              label="Fake User Email"
              size="small"
              maxwidth="200px"
              value={email}
              onChange={handle_email}
              required
            />
            <Button type="submit" form="login-as-form" color="primary" variant="contained" size="small">
              Login
            </Button>
          </form>
        </>
      )}
    </>
  );
};

const LogoutView = ({ userStateDispatcher }) => {
  useEffect(() => {
    successfulLogout(userStateDispatcher);
  }, [userStateDispatcher]);
  return (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
};

function userReducer(state, action) {
  if (action.type === 'loginUser') {
    return {
      ...state,
      ...action.payload,
    };
  }
  if (action.type === 'setAccountSubscriptionList') {
    const { subscribed_accounts } = action.payload;
    return {
      ...state,
      subscribed_accounts: new Set(subscribed_accounts),
    };
  }
  if (action.type === 'setDefaultSalesTarget') {
    const { default_sales_target } = action.payload;
    return {
      ...state,
      default_sales_target,
    };
  }
  if (action.type === 'logoutUser') {
    localStorage.removeItem('aleph-token');
    localStorage.removeItem('fake-aleph-token');
    return _.clone(defaultUserContext);
  }
  if (action.type === 'setUser') {
    return {
      ...state,
      aleph_user: action.payload.user,
    };
  }
  return state;
}

async function successfulLogin(dispatcher, response) {
  const aleph_login = await login(response.credential);
  if (aleph_login.success === true) {
    dispatcher({
      type: 'loginUser',
      payload: {
        authenticated: true,
        alephToken: aleph_login.token,
      },
    });
    localStorage.setItem('aleph-token', aleph_login.token);
  } else {
    if (aleph_login.user) {
      dispatcher({
        type: 'loginUser',
        payload: {
          authenticated: 'pending',
        },
      });
    }
  }
}

function successfulLogout(userStateDispatcher) {
  console.log('successful logout');
  userStateDispatcher({
    type: 'logoutUser',
  });
}

/*
function failedLogout(response, userStateDispatcher){
    console.log('failed to logout:', response)
    userStateDispatcher({
        type: 'logoutUser'
    })

}
*/

const withAPI = (Component) => (props) => (
  <userContext.Consumer>
    {({ userState, userStateDispatcher }) => (
      <Component api={get_api_caller(_.get(userState, 'alephToken'), userStateDispatcher)} {...props} />
    )}
  </userContext.Consumer>
);

const useAPI = () => {
  const { userState, userStateDispatcher } = useContext(userContext);
  const tkn = _.get(userState, 'alephToken');
  return useMemo(() => get_api_caller(tkn, userStateDispatcher), [tkn, userStateDispatcher]);
};

const useAdmin = () => {
  const { userState, adminOff } = useContext(userContext);
  return is_admin({ ...userState, adminOff });
};

const useUserState = () => {
  return useContext(userContext);
};

function withUserState(Componenet) {
  return (props) => (
    <userContext.Consumer>
      {({ userState, userStateDispatcher, adminOff }) => (
        <Componenet userState={{ ...userState, adminOff }} userStateDispatcher={userStateDispatcher} {...props} />
      )}
    </userContext.Consumer>
  );
}

const LoginController = withUserState(LoginButton);
const LogoutController = withUserState(LogoutView);
const ImpersonateController = withUserState(ImpersonateForm);

const is_admin = (user) => {
  if (_.get(user, 'adminOff')) {
    return false;
  } else {
    return (
      _.get(user, 'aleph_user.Aleph_Connect_Role__c') === 'Admin' ||
      _.get(user, 'Aleph_Connect_Role__c') === 'Admin' ||
      _.get(user, 'aleph_user.domain') === 'aleph.vc' ||
      _.get(user, 'domain') === 'aleph.vc'
    );
  }
};

const is_fake = (user) => {
  if (_.get(user, 'fake')) {
    return true;
  } else {
    return _.get(user, 'aleph_user.fake') === true;
  }
};

export const is_talpod = (user) => {
  if (_.get(user, 'adminOff')) {
    return false;
  } else {
    return (
      ['tal@aleph.vc', 'bar@aleph.vc', 'hadar@aleph.vc', 'joanne@aleph.vc', 'elad@aleph.vc', 'sagiv@aleph.vc'].includes(
        _.get(user, 'aleph_user.payload.email'),
      ) ||
      ['tal@aleph.vc', 'bar@aleph.vc', 'hadar@aleph.vc', 'joanne@aleph.vc', 'elad@aleph.vc', 'sagiv@aleph.vc'].includes(
        _.get(user, 'payload.email'),
      )
    );
  }
};

export const is_defpod = (user) => {
  if (_.get(user, 'adminOff')) {
    return false;
  } else {
    return (
      ['gilad@aleph.vc', 'gili@aleph.vc', 'eilon@aleph.vc'].includes(_.get(user, 'aleph_user.payload.email')) ||
      ['gilad@aleph.vc', 'gili@aleph.vc', 'eilon@aleph.vc'].includes(_.get(user, 'payload.email'))
    );
  }
};

export const is_salespod = (user) => {
  const salespod = ['sagiv@aleph.vc', 'hila@aleph.vc', 'maayan@aleph.vc'];
  if (_.get(user, 'adminOff')) {
    return false;
  } else {
    return !_.isEmpty(
      _.intersection(salespod, [_.get(user, 'aleph_user.payload.email'), _.get(user, 'payload.email')]),
    );
  }
};

const FOCUS_USERS = [
  'maya.eivensitz@lemonade.com',
  'noy.madorsky@lemonade.com',
  'dor@melio.com',
  'noa.tzafrir@melio.com',
  'yonatan.bakalash@melio.com',
  'taly@joindaisy.com',
];

export const is_focus = (user) => {
  const emailFromAlephUser = _.get(user, 'aleph_user.payload.email', '');
  const emailFromPayload = _.get(user, 'payload.email', '');
  const alephConnectRole = _.get(user, 'aleph_user.Aleph_Connect_Role__c', '') || '';
  const emails = [emailFromAlephUser, emailFromPayload].map(_.toLower);
  const isFocusEmail = emails.some((email) => FOCUS_USERS.includes(email));
  return isFocusEmail || alephConnectRole.includes('Focus user');
};

export const isTamnuniUser = (user) => {
  const alephConnectRole = _.get(user, 'aleph_user.Aleph_Connect_Role__c') || '';
  return alephConnectRole.includes('Tamnuni user');
};

export const is_tal = (user) => {
  return (
    ['tal@aleph.vc'].includes(_.get(user, 'aleph_user.payload.email')) ||
    ['tal@aleph.vc'].includes(_.get(user, 'payload.email'))
  );
};

export const is_bar = (user) => {
  return (
    ['bar@aleph.vc'].includes(_.get(user, 'aleph_user.payload.email')) ||
    ['bar@aleph.vc'].includes(_.get(user, 'payload.email'))
  );
};

export const is_funding = (user) => {
  return !_.isEmpty(
    _.intersection(['gali@aleph.vc'], [_.get(user, 'aleph_user.payload.email'), _.get(user, 'payload.email')]),
  );
};

const getUserAccountId = (user) => _.get(user, 'aleph_user.AccountId');
const getUserAccountName = (user) => _.get(user, 'aleph_user.Account.Name');
const getUserContactId = (user) => _.get(user, 'aleph_user.Id', _.get(user, 'Id'));
const getUserFirstName = (user) => _.get(user, 'aleph_user.payload.given_name', _.get(user, 'payload.given_name'));

const getUserPublicId = (user) => _.get(user, 'linkedin_public_idenfitier__c');

const getUserEmail = (user) => {
  const emailFromAlephUser = _.get(user, 'aleph_user.payload.email', '');
  const emailFromPayload = _.get(user, 'payload.email', '');
  return emailFromAlephUser || emailFromPayload;
};
const getUserAlephConnectRole = (user) =>
  _.get(user, 'aleph_user.Aleph_Connect_Role__c', _.get(user, 'Aleph_Connect_Role__c'));
export {
  LoginController,
  LogoutController,
  userContext,
  userReducer,
  withAPI,
  useAPI,
  withUserState,
  useUserState,
  useAdmin,
  is_admin,
  ImpersonateController,
  getUserAccountId,
  getUserContactId,
  getUserFirstName,
  getUserAccountName,
  getUserAlephConnectRole,
  getUserEmail,
  is_fake,
  killFake,
  getUserPublicId,
};
