import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { getRelevantRelationship } from '../../utils/connectors';
import { isAlephOrPortco } from '../../utils/contacts';
import { POTENTIAL_INTRO_GOALS, SKILL_CATEGORIES, SKILLS } from '../../utils/sf-consts';

export const getAllConnectors = (target) =>
  _.concat(_.get(target, 'pastColleagues', []), _.get(target, 'otherConnectors', []));

const YearsSinceSlider = ({
  connectorFilters,
  setConnectorFilters,
  optionalConnectorFilters,
  label = 'Years Since',
}) => {
  const handleSliderChange = (event, newValue) => {
    const [, yearsSince] = newValue;
    setConnectorFilters({
      ..._.omit(connectorFilters, ['yearsSince']),
      yearsSince,
    });
  };
  return (
    <FormControl style={{ width: '80%' }}>
      <InputLabel id="years-selector"> {label} </InputLabel>
      <Slider
        value={[0, connectorFilters.yearsSince]}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        step={1}
        min={0}
        max={50}
      />
    </FormControl>
  );
};

export const MonthsSlider = ({
  connectorFilters,
  setConnectorFilters,
  optionalConnectorFilters,
  label = 'Months Together',
}) => {
  const handleSliderChange = (event, newValue) => {
    const [monthsMin, monthsMax] = newValue;
    setConnectorFilters({
      ..._.omit(connectorFilters, ['monthsMin', 'monthsMax']),
      monthsMin,
      monthsMax,
    });
  };
  return (
    <FormControl style={{ width: '80%' }}>
      <InputLabel id="months-selector"> {label} </InputLabel>
      <Slider
        value={[connectorFilters.monthsMin, connectorFilters.monthsMax]}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        step={1}
        min={0}
        max={optionalConnectorFilters.monthsMax}
      />
    </FormControl>
  );
};

export const createOptionsByTargetContact = (allConnectors) => {
  const allRoles = _(allConnectors)
    .map(({ roles }) => roles)
    .flatten()
    .compact()
    .value();
  return _.concat(
    _(allRoles)
      .map(({ Account_Name__c }) => ({ type: 'Company', value: Account_Name__c }))
      .filter(({ value }) => !_.isEmpty(value))
      .uniqBy('value')
      .sort()
      .value(),
    _(allRoles)
      .map(({ Role__c }) => ({ type: 'Role', value: Role__c || 'Other' }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allRoles)
      .map(({ Active__c }) => ({ type: 'Active', value: Active__c ? 'Active roles only' : 'Previous roles only' }))
      .uniqBy('value')
      .sort()
      .value(),
    { type: 'Portfolio', value: 'Portfolio Employee' },
    _(allRoles)
      .map(({ Skill_Category__c }) => ({
        type: 'Skill Category',
        value:
          _.get(
            SKILL_CATEGORIES.find(({ apiName }) => Skill_Category__c === apiName),
            'label',
          ) || 'Other',
      }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allRoles)
      .map(({ Skill__c }) => ({
        type: 'Skill',
        value:
          _.get(
            SKILLS.find(({ apiName }) => Skill__c === apiName),
            'label',
          ) || 'Other',
      }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allRoles)
      .map(({ account_tags }) => (_.isEmpty(account_tags) ? '' : account_tags.split(';')))
      .compact()
      .flatten()
      .map((account_tags) => ({ type: 'Account Tag', value: account_tags }))
      .uniqBy('value')
      .value(),
    _(allConnectors)
      .map(({ relationshipWithOwner }) => ({ type: 'Relation to Owner', value: relationshipWithOwner }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allConnectors)
      .map(({ owner_name }) => ({ type: 'Owner', value: owner_name || 'Other' }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allRoles)
      .filter(({ Account_Name__c }) => !!Account_Name__c)
      .map(({ Account_Name__c }) => ({ type: 'Exclude', subType: 'Company', value: Account_Name__c }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allRoles)
      .map(({ Role__c }) => ({ type: 'Exclude', subType: 'Role', value: Role__c || 'Other' }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allConnectors)
      .map(({ owner_name }) => ({ type: 'Exclude', subType: 'Owner', value: owner_name || 'Other' }))
      .uniqBy('value')
      .sort()
      .value(),
    { type: 'Exclude', subType: 'Portfolio', value: 'Portfolio Employee' },
    _(allConnectors)
      .map(({ relationshipWithOwner }) => ({
        type: 'Exclude',
        subType: 'Relation to Owner',
        value: relationshipWithOwner,
      }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allRoles)
      .map(({ account_tags }) => (_.isEmpty(account_tags) ? '' : account_tags.split(';')))
      .compact()
      .flatten()
      .map((account_tags) => ({ type: 'Exclude', subType: 'Account Tag', value: account_tags }))
      .uniqBy('value')
      .value(),
  );
};

export const createOptionsCompanyOverview = (allConnectors) => {
  return _.concat(
    _(allConnectors)
      .map(({ Account_Name__c }) => ({ type: 'Company', value: Account_Name__c }))
      .filter(({ value }) => !_.isEmpty(value))
      .uniqBy('value')
      .sort()
      .value(),
    _(allConnectors)
      .map(({ Role__c }) => ({ type: 'Role', value: Role__c || 'Other' }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allConnectors)
      .map(({ Active__c }) => ({ type: 'Active', value: Active__c ? 'Active roles only' : 'Previous roles only' }))
      .uniqBy('value')
      .sort()
      .value(),
    { type: 'Portfolio', value: 'Portfolio Employee' },
    _(allConnectors)
      .map(({ owner_name }) => ({ type: 'Owner', value: owner_name || 'Other' }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allConnectors)
      .map(({ Skill_Category__c }) => ({
        type: 'Skill Category',
        value:
          _.get(
            SKILL_CATEGORIES.find(({ apiName }) => Skill_Category__c === apiName),
            'label',
          ) || 'Other',
      }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allConnectors)
      .map(({ Skill__c }) => ({
        type: 'Skill',
        value:
          _.get(
            SKILLS.find(({ apiName }) => Skill__c === apiName),
            'label',
          ) || 'Other',
      }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allConnectors)
      .map(({ relationshipWithOwner }) => ({ type: 'Relation to Owner', value: relationshipWithOwner }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allConnectors)
      .map(({ account_tags }) => (_.isEmpty(account_tags) ? '' : account_tags.split(';')))
      .compact()
      .flatten()
      .map((account_tags) => ({ type: 'Account Tag', value: account_tags }))
      .uniqBy('value')
      .value(),
    _(allConnectors)
      .filter(({ Account_Name__c }) => !!Account_Name__c)
      .map(({ Account_Name__c }) => ({ type: 'Exclude', subType: 'Company', value: Account_Name__c }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allConnectors)
      .map(({ Role__c }) => ({ type: 'Exclude', subType: 'Role', value: Role__c || 'Other' }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allConnectors)
      .map(({ owner_name }) => ({ type: 'Exclude', subType: 'Owner', value: owner_name || 'Other' }))
      .uniqBy('value')
      .sort()
      .value(),
    { type: 'Exclude', subType: 'Portfolio', value: 'Portfolio Employee' },
    _(allConnectors)
      .map(({ relationshipWithOwner }) => ({
        type: 'Exclude',
        subType: 'Relation to Owner',
        value: relationshipWithOwner,
      }))
      .uniqBy('value')
      .sort()
      .value(),
    _(allConnectors)
      .map(({ account_tags }) => (_.isEmpty(account_tags) ? '' : account_tags.split(';')))
      .compact()
      .flatten()
      .map((account_tags) => ({ type: 'Exclude', subType: 'Account Tag', value: account_tags }))
      .uniqBy('value')
      .value(),
  );
};

export const roleFilterer = (role, connectorFilters) => {
  const companies = connectorFilters.options.filter(({ type }) => type === 'Company').map(({ value }) => value);
  const skillCategories = connectorFilters.options
    .filter(({ type }) => type === 'Skill Category')
    .map(({ value }) => value);
  const skills = connectorFilters.options.filter(({ type }) => type === 'Skill').map(({ value }) => value);
  const active = connectorFilters.options.filter(({ type }) => type === 'Active').map(({ value }) => value);
  const roles = connectorFilters.options.filter(({ type }) => type === 'Role').map(({ value }) => value);
  const excludeCompanies = connectorFilters.options
    .filter(({ type, subType }) => type === 'Exclude' && subType === 'Company')
    .map(({ value }) => value);
  const excludeRoles = connectorFilters.options
    .filter(({ type, subType }) => type === 'Exclude' && subType === 'Role')
    .map(({ value }) => value);

  const { Account_Name__c, Role__c, Skill_Category__c, Skill__c, Active__c, overlappingMonths } = role;
  const yearsSince = moment().diff(_.get(role, 'endDateTogether'), 'days') / 365;

  return !(
    (!_.isEmpty(roles) && !_.includes(roles, Role__c)) ||
    (!_.isEmpty(skillCategories) && !_.includes(skillCategories, Skill_Category__c)) ||
    (!_.isEmpty(skills) && !_.includes(skills, Skill__c)) ||
    (!_.isEmpty(active) && !_.includes(active, Active__c ? 'Active roles only' : 'Previous roles only')) ||
    (!_.isEmpty(companies) && !_.includes(companies, Account_Name__c)) ||
    (!_.isEmpty(excludeCompanies) && _.includes(excludeCompanies, Account_Name__c)) ||
    (!_.isEmpty(excludeRoles) && _.includes(excludeRoles, Role__c)) ||
    (_.isNumber(overlappingMonths) &&
      overlappingMonths > 0 &&
      (overlappingMonths < connectorFilters.monthsMin || overlappingMonths > connectorFilters.monthsMax)) ||
    (_.isNumber(yearsSince) && yearsSince > 0 && yearsSince > connectorFilters.yearsSince)
  );
};

export const ownerFilterer = (contact, options) => {
  const owners = options.filter(({ type }) => type === 'Owner').map(({ value }) => value);
  const relationships = options.filter(({ type }) => type === 'Relation to Owner').map(({ value }) => value);
  const excludeOwners = options
    .filter(({ type, subType }) => type === 'Exclude' && subType === 'Owner')
    .map(({ value }) => value);
  const excludeRelationships = options
    .filter(({ type, subType }) => type === 'Exclude' && subType === 'Relation to Owner')
    .map(({ value }) => value);
  const STRONG_RELATIONSHIP_SCORE = 3;
  const { owner_name, score } = contact;
  const relevantRelationship = getRelevantRelationship(contact);
  return (
    (_.isEmpty(owners) || _.includes(owners, owner_name)) &&
    (_.isEmpty(excludeOwners) || !_.includes(excludeOwners, owner_name) || score >= STRONG_RELATIONSHIP_SCORE) &&
    (_.isEmpty(excludeRelationships) || !_.includes(excludeRelationships, relevantRelationship)) &&
    (_.isEmpty(relationships) || _.includes(relationships, relevantRelationship))
  );
};

export const employeeFilterer = ({ connectorFilters, potentialConnectors }) => {
  const accountTags = connectorFilters.options.filter(({ type }) => type === 'Account Tag').map(({ value }) => value);
  const accountTagsToExclude = connectorFilters.options
    .filter(({ type, subType }) => type === 'Exclude' && subType === 'Account Tag')
    .map(({ value }) => value);
  const portcoOnly = connectorFilters.options.find(({ type }) => type === 'Portfolio');
  const noPortco = connectorFilters.options.find(({ type, subType }) => type === 'Exclude' && subType === 'Portfolio');

  return potentialConnectors.filter((c) => {
    const hasRelevantRole = //todo: fix. some contacts are filtered by the role and others have a list of roles
      _.has(c, 'roles')
        ? !!_.get(c, 'roles', []).find((r) => roleFilterer(r, connectorFilters))
        : !!roleFilterer(c, connectorFilters);

    const hasSignals = !_.isEmpty(c.signals);

    return (
      ownerFilterer(c, connectorFilters.options) &&
      (_.isEmpty(accountTags) || !_.isEmpty(_.intersection(accountTags, _.get(c, 'account_tags').split(';')))) &&
      (_.isEmpty(accountTagsToExclude) ||
        _.isEmpty(_.intersection(accountTagsToExclude, _.get(c, 'account_tags').split(';')))) &&
      (_.isEmpty(portcoOnly) || isAlephOrPortco(c)) &&
      (_.isEmpty(noPortco) || !isAlephOrPortco(c)) &&
      (!_.isNumber(connectorFilters.yearsOfExperienceMin) ||
        (connectorFilters.yearsOfExperienceMin <= _.get(c, 'years') &&
          connectorFilters.yearsOfExperienceMax >= _.get(c, 'years'))) &&
      (hasRelevantRole || hasSignals)
    );
  });
};

const RelationshipCategorySelector = ({ connectorFilters, setConnectorFilters }) => {
  const handleRelationChange = (event) => {
    const { value } = event.target;
    setConnectorFilters({
      ..._.omit(connectorFilters, ['min_relationship']),
      min_relationship: value,
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 35,
      },
    },
  };

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel id="rel-category-select-input" style={{ width: '300px' }}>
        Minimal Relationship Category
      </InputLabel>
      <Select
        labelId="rel-category-select-label"
        id="rel-category-select"
        value={connectorFilters.min_relationship}
        onChange={handleRelationChange}
        input={<Input />}
        MenuProps={MenuProps}
      >
        {_.range(5).map((category) => (
          <MenuItem key={'category-' + category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const LinkedinConnectionSelector = ({ connectorFilters, setConnectorFilters }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    setConnectorFilters({
      ..._.omit(connectorFilters, 'linkedinConnection'),
      linkedinConnection: value,
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const POTENTIAL_LINKEDIN_FILTERS = [
    'All',
    'Only Connections',
    '#Employees < 1000',
    '#Employees < 500',
    '#Employees < 200',
    '#Employees < 100',
  ];

  return (
    <div>
      <FormControl>
        <InputLabel id="linkedin-conn-filters" style={{ marginBottom: '8px', width: '300px' }}>
          Not Connected but Shared Experience
        </InputLabel>
        <Select
          labelId="linkedin-conn-filters-label"
          id="linkedin-conn-filters-name"
          value={connectorFilters.linkedinConnection}
          onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
          style={{ width: '250px' }}
        >
          {POTENTIAL_LINKEDIN_FILTERS.map((filter) => (
            <MenuItem key={filter} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const IntroGoalSelector = ({ introGoal, setIntroGoal }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    setIntroGoal(value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };

  return (
    <div>
      <FormControl>
        <InputLabel id="demo-mutiple-name-label"> Intro's Goal</InputLabel>
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          value={introGoal}
          onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
          style={{ width: '200px' }}
        >
          {POTENTIAL_INTRO_GOALS.map((goal) => (
            <MenuItem key={goal} value={goal}>
              {goal}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

function CurrentColleagues({ connectorFilters, setConnectorFilters }) {
  const handleCurrentColleaguesChange = () => {
    setConnectorFilters({
      ..._.omit(connectorFilters, 'showCurrentColleagues'),
      showCurrentColleagues: !connectorFilters.showCurrentColleagues,
    });
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={connectorFilters.showCurrentColleagues}
                onChange={handleCurrentColleaguesChange}
                name="show-current-colleagues"
              />
            }
            label="Show Current Colleagues"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}

function JustLinkedinSignal({ connectorFilters, setConnectorFilters }) {
  const handleJustLinkedinChange = () => {
    setConnectorFilters({
      ..._.omit(connectorFilters, 'showJustLinkedin'),
      showJustLinkedin: !connectorFilters.showJustLinkedin,
    });
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={connectorFilters.showJustLinkedin}
                onChange={handleJustLinkedinChange}
                name="show-just-linkedin"
              />
            }
            label="Show 'Just Linkedin' connectors"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}

function LinkedinCommentSignal({ connectorFilters, setConnectorFilters }) {
  const handleLinkedinCommentChange = () => {
    setConnectorFilters({
      ..._.omit(connectorFilters, 'showLinkedinComments'),
      showLinkedinComments: !connectorFilters.showLinkedinComments,
    });
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={connectorFilters.showLinkedinComments}
                onChange={handleLinkedinCommentChange}
                name="show-linkedin-comments"
              />
            }
            label="Show Linkedin Comments"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}

export const ConnectorAutoCompleteFilter = ({ optionalConnectorFilters, setConnectorFilters, connectorFilters }) => {
  const handleOptionSelection = (event, option) => {
    setConnectorFilters({
      ..._.omit(connectorFilters, ['options']),
      options: option,
    });
  };
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
  });
  return (
    <Autocomplete
      filterOptions={filterOptions}
      getOptionLabel={(option) => `${option.type === 'Exclude' ? '-' : ''}${option.value}`}
      options={optionalConnectorFilters.options}
      groupBy={(option) => option.type}
      value={connectorFilters.options}
      getOptionSelected={(option, value) => _.isEqual(option, value)}
      autoComplete
      multiple
      onChange={handleOptionSelection}
      selectOnFocus={false}
      freeSolo={false}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={`${option.type === 'Exclude' ? '-' : ''}${option.value}`}
            {...getTagProps({ index })}
            style={{ backgroundColor: option.type === 'Exclude' ? '#fcbcb5' : '#e0e0e0' }}
          />
        ))
      }
      renderInput={(params) => (
        <TextField className={'searchbox'} variant="outlined" style={{ borderRadius: '32px' }} {...params} />
      )}
    />
  );
};

export const ConnectorsFilters = ({
  connectorFilters,
  setConnectorFilters,
  optionalConnectorFilters,
  selectedCount,
  totalCount,
  introGoal,
  setIntroGoal,
}) => {
  const handleShowAll = () => {
    const clean_filters = {
      ..._.omit(optionalConnectorFilters, ['options']),
      options: [],
      min_relationship: 0,
      showCurrentColleagues: true,
      showJustLinkedin: true,
      showLinkedinComments: true,
      linkedinConnection: 'All',
      yearsSince: 100,
      monthsMin: 0,
    };
    console.log({ clean_filters });
    setConnectorFilters(clean_filters);
  };

  return (
    <>
      <Grid container style={{ paddingTop: '20px' }} spacing={2}>
        <Grid item md={12} xl={12} lg={12}>
          <div style={{ marginBottom: '20px' }}>
            <div>
              <ConnectorAutoCompleteFilter
                optionalConnectorFilters={optionalConnectorFilters}
                setConnectorFilters={setConnectorFilters}
                connectorFilters={connectorFilters}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ paddingTop: '3px' }} justifyContent={'space-between'}>
        <Grid item md={3} xl={3} lg={3}>
          <IntroGoalSelector introGoal={introGoal} setIntroGoal={setIntroGoal} />
        </Grid>
        <Grid item md={3} xl={3} lg={3}>
          <LinkedinConnectionSelector connectorFilters={connectorFilters} setConnectorFilters={setConnectorFilters} />
        </Grid>
        <Grid item md={3} xl={3} lg={3}>
          <RelationshipCategorySelector connectorFilters={connectorFilters} setConnectorFilters={setConnectorFilters} />
        </Grid>
      </Grid>
      <Grid container style={{ paddingTop: '30px' }} justifyContent={'space-between'}>
        <Grid item md={3} xl={3} lg={3}>
          <Typography variant="body2" component="p" style={{ display: 'inline-block' }}>
            showing{' '}
            <b>
              {selectedCount}/{totalCount}{' '}
            </b>{' '}
            results
          </Typography>
          <Button
            style={{ marginLeft: '10px' }}
            variant="outlined"
            size="small"
            onClick={handleShowAll}
            disabled={selectedCount === totalCount}
          >
            Show All
          </Button>
        </Grid>

        <Grid item md={2} xl={2} lg={2}>
          <CurrentColleagues connectorFilters={connectorFilters} setConnectorFilters={setConnectorFilters} />
        </Grid>
        <Grid item md={2} xl={2} lg={2}>
          <JustLinkedinSignal connectorFilters={connectorFilters} setConnectorFilters={setConnectorFilters} />
        </Grid>
        <Grid item md={2} xl={2} lg={2}>
          <LinkedinCommentSignal connectorFilters={connectorFilters} setConnectorFilters={setConnectorFilters} />
        </Grid>
        <Grid item md={3} xl={3} lg={3}>
          <MonthsSlider
            connectorFilters={connectorFilters}
            setConnectorFilters={setConnectorFilters}
            optionalConnectorFilters={optionalConnectorFilters}
            label={'Months Together'}
          />
        </Grid>
        <Grid item md={2} xl={2} lg={2}>
          <YearsSinceSlider
            connectorFilters={connectorFilters}
            setConnectorFilters={setConnectorFilters}
            optionalConnectorFilters={optionalConnectorFilters}
            label={'Years Since'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const getMonthsMin = (allConnectors) =>
  _(allConnectors)
    .map((c) => (_.has(c, 'roles') ? _.get(c, 'roles') : c))
    .flatten()
    .map(({ overlappingMonths }) => _.isNumber(overlappingMonths) && overlappingMonths)
    .compact()
    .min();

export const getMonthsMax = (allConnectors) =>
  _(allConnectors)
    .map((c) => (_.has(c, 'roles') ? _.get(c, 'roles') : c))
    .flatten()
    .map(({ overlappingMonths }) => _.isNumber(overlappingMonths) && overlappingMonths)
    .compact()
    .max();

export const getYearsMin = (allConnectors) =>
  _(allConnectors)
    .map(({ years }) => _.isNumber(years) && _.floor(years))
    .compact()
    .min();

export const getYearsMax = (allConnectors) =>
  _(allConnectors)
    .map(({ years }) => _.isNumber(years) && _.ceil(years))
    .compact()
    .max();

export const createOptionalFiltersTargetContact = (target) => ({
  monthsMin: getMonthsMin(getAllConnectors(target)),
  monthsMax: getMonthsMax(getAllConnectors(target)),
  options: createOptionsByTargetContact(getAllConnectors(target)),
});

export const createOptionalEmployeesFilters = (potentialConnecotrs) => ({
  monthsMin: getMonthsMin(potentialConnecotrs),
  monthsMax: getMonthsMax(potentialConnecotrs),
  yearsOfExperienceMin: getYearsMin(potentialConnecotrs),
  yearsOfExperienceMax: getYearsMax(potentialConnecotrs),
  options: createOptionsCompanyOverview(potentialConnecotrs),
});

export const createInitialFiltersCompanyOverview = (allConnectors) => {
  const excludeByDefault = [
    { type: 'Exclude', subType: 'Relation to Owner', value: 'Not Familiar' },
    { type: 'Exclude', subType: 'Relation to Owner', value: 'Familiar - Weak Relationship' },
    { type: 'Exclude', subType: 'Owner', value: 'Ampliphy Ampliphius' },
    { type: 'Exclude', subType: 'Role', value: 'Advisor' },
    { type: 'Exclude', subType: 'Role', value: 'Board Member' },
    { type: 'Exclude', subType: 'Role', value: 'Consultant' },
    { type: 'Exclude', subType: 'Role', value: 'Investor' },
  ];

  return {
    options: _.intersectionBy(excludeByDefault, createOptionsCompanyOverview(allConnectors), 'value'),
    monthsMin: _.max([getMonthsMin(allConnectors), 0.5]),
    monthsMax: getMonthsMax(allConnectors),
    yearsOfExperienceMin: getYearsMin(allConnectors),
    yearsOfExperienceMax: getYearsMax(allConnectors),
    min_relationship: 1,
    showCurrentColleagues: false,
    showJustLinkedin: false,
    showLinkedinComments: false,
    linkedinConnection: '#Employees < 1000',
    yearsSince: 50,
  };
};

export const createInitialFiltersByTargetContact = (allConnectors) => {
  const excludeByDefault = [
    { type: 'Exclude', subType: 'Relation to Owner', value: 'Not Familiar' },
    { type: 'Exclude', subType: 'Relation to Owner', value: 'Familiar - Weak Relationship' },
    { type: 'Exclude', subType: 'Owner', value: 'Ampliphy Ampliphius' },
    { type: 'Exclude', subType: 'Role', value: 'Advisor' },
    { type: 'Exclude', subType: 'Role', value: 'Board Member' },
    { type: 'Exclude', subType: 'Role', value: 'Consultant' },
    { type: 'Exclude', subType: 'Role', value: 'Investor' },
  ];
  return {
    options: _.intersectionBy(excludeByDefault, createOptionsByTargetContact(allConnectors), 'value'),
    monthsMin: _.max([getMonthsMin(allConnectors), 5]),
    monthsMax: getMonthsMax(allConnectors),
    min_relationship: 1,
    showCurrentColleagues: false,
    showJustLinkedin: false,
    showLinkedinComments: false,
    linkedinConnection: '#Employees < 1000',
    yearsSince: 50,
  };
};

export const createInitialFiltersByTargetContactForSales = (allConnectors) => {
  const excludeByDefault = [{ type: 'Exclude', subType: 'Relation to Owner', value: 'Not Familiar' }];
  return {
    options: _.intersectionBy(excludeByDefault, createOptionsByTargetContact(allConnectors), 'value'),
    monthsMin: _.max([getMonthsMin(allConnectors), 0]),
    monthsMax: getMonthsMax(allConnectors),
    min_relationship: 3,
    showCurrentColleagues: true,
    showJustLinkedin: false,
    showLinkedinComments: true,
    linkedinConnection: '#Employees < 100',
    yearsSince: 5,
  };
};

export const createInitialFiltersByTargetContactForDefpod = (allConnectors) => {
  const excludeByDefault = [
    { type: 'Exclude', subType: 'Relation to Owner', value: 'Not Familiar' },
    { type: 'Exclude', subType: 'Relation to Owner', value: 'Familiar - Weak Relationship' },
    { type: 'Exclude', subType: 'Owner', value: 'Ampliphy Ampliphius' },
    { type: 'Exclude', subType: 'Role', value: 'Advisor' },
    { type: 'Exclude', subType: 'Role', value: 'Board Member' },
    { type: 'Exclude', subType: 'Role', value: 'Consultant' },
    { type: 'Exclude', subType: 'Role', value: 'Investor' },
  ];
  return {
    options: _.intersectionBy(excludeByDefault, createOptionsByTargetContact(allConnectors), 'value'),
    monthsMin: _.max([getMonthsMin(allConnectors), 12]),
    monthsMax: getMonthsMax(allConnectors),
    min_relationship: 3,
    showCurrentColleagues: false,
    showJustLinkedin: false,
    showLinkedinComments: false,
    linkedinConnection: '#Employees < 1000',
    yearsSince: 50,
  };
};
