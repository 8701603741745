import { SvgIcon } from '@material-ui/core';
import React from 'react';

import { ReactComponent as agora } from '../static/portco-favicons/agora.svg';
import { ReactComponent as aleph } from '../static/portco-favicons/aleph.svg';
import { ReactComponent as bringg } from '../static/portco-favicons/bringg.svg';
import { ReactComponent as coralogix } from '../static/portco-favicons/coralogix.svg';
import { ReactComponent as daisy } from '../static/portco-favicons/daisy.svg';
import { ReactComponent as finaloop } from '../static/portco-favicons/finaloop.svg';
import { ReactComponent as healthy } from '../static/portco-favicons/healthy.svg';
import { ReactComponent as honeybook } from '../static/portco-favicons/honeybook.svg';
import { ReactComponent as lemonade } from '../static/portco-favicons/lemonade.svg';
import { ReactComponent as melio } from '../static/portco-favicons/melio.svg';
import { ReactComponent as nextSilicon } from '../static/portco-favicons/nextsilicon.svg';
import { ReactComponent as placer } from '../static/portco-favicons/placer.svg';
import { ReactComponent as q } from '../static/portco-favicons/q.svg';
import { ReactComponent as simply } from '../static/portco-favicons/simply.svg';
import { ReactComponent as trullion } from '../static/portco-favicons/trullion.svg';
import { ReactComponent as unit } from '../static/portco-favicons/unit.svg';

export const PortcoFavicon = ({ portcoName, width = 30, height = 30, verticalAlign = 'bottom' }) => {
  switch (portcoName) {
    case 'Lemonade':
      return <SvgIcon component={lemonade} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Melio':
      return <SvgIcon component={melio} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Daisy':
      return <SvgIcon component={daisy} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'HoneyBook':
      return <SvgIcon component={honeybook} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Healthy.io':
      return <SvgIcon component={healthy} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Placer.ai':
      return <SvgIcon component={placer} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Aleph':
      return <SvgIcon component={aleph} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Bringg':
      return <SvgIcon component={bringg} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Unit':
      return <SvgIcon component={unit} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Agora RE':
      return <SvgIcon component={agora} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Trullion':
      return <SvgIcon component={trullion} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'NextSilicon':
      return <SvgIcon component={nextSilicon} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Simply':
      return <SvgIcon component={simply} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Q.ai':
      return <SvgIcon component={q} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    case 'Finaloop':
      return <SvgIcon component={finaloop} style={{ width, height, verticalAlign }} viewBox={'0 0 30 30'} />;
    default:
      return <></>;
  }
};
