import { Link, Tooltip } from '@material-ui/core';
import _, { set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import loadingAnimation from '../../static/focus/loading.png';
import {
  getUserAccountName,
  ImpersonateController,
  is_fake,
  is_focus,
  is_talpod,
  killFake,
  useAPI,
  withUserState,
} from '../../userContext';
import { PortcoFavicon } from '../../widgets/portco-favicons';
import { check_missing_profile, get_portco_connectors_mini, public_identifier_to_entity_urn } from './api';
import { UserFeedback } from './feedback';
import { ConnectorScoreMini } from './utils';

function geFocusPageUrl({ public_identifier, entityUrn, source, fullName }) {
  return `../#/talent/focus/?linkedin_id=${public_identifier}&entityUrn=${entityUrn}&source=${source}&fullName=${fullName}`;
}

const _FocusMini = ({ userState }) => {
  const api = useAPI();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const fullName = urlParams.get('fullName');
  const [connectors, setConnectors] = useState([]);
  const [target, setTarget] = useState();
  const [loading, setLoading] = useState(true);
  const [showFeedback, setFeedback] = useState(false);
  const [requested, setRequested] = useState(false);
  const publicIdentifierParam = urlParams.get('linkedin_id');
  const [{ publicIdentifier, entityUrn, mode, source, loadingEntityUrn }, setState] = useState({
    publicIdentifier: publicIdentifierParam,
    loadingEntityUrn: false,
  });
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const onMessage = (event) => {
      if (event.data.type === 'entityUrn') {
        setState((state) => ({ ...state, mode: 'entityUrn', entityUrn: event.data.entityUrn, source: 'linkedin' }));
      } else if (event.data.type === 'publicIdentifier') {
        setState((state) => ({
          ...state,
          mode: 'publicIdentifier',
          publicIdentifier: event.data.publicIdentifier,
          source: 'recruiter',
          loadingEntityUrn: true,
        }));
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    const fetchEntityUrn = async () => {
      if (mode === 'publicIdentifier' && publicIdentifier && !entityUrn) {
        const { entityUrn } = await public_identifier_to_entity_urn({ public_identifier: publicIdentifier }, api);
        if (entityUrn) {
          setState((state) => ({ ...state, mode: 'entityUrn', entityUrn, loadingEntityUrn: false }));
        } else {
          setState((state) => ({ ...state, loadingEntityUrn: false }));
        }
      }
    };
    fetchEntityUrn();
  }, [mode, publicIdentifier, entityUrn]);

  const checkMissing = async () => {
    await check_missing_profile({ public_identifier: publicIdentifier, fullName }, api);
    setRequested(true);
  };

  const toggleFeedback = () => setFeedback(!showFeedback);
  const ConnectorsText = () => {
    const top3 = _(connectors)
      .map(({ connector_name }) => connector_name)
      .uniq()
      .take(3)
      .value();

    const additional =
      connectors.length > 3
        ? ` and ${connectors.length - 3} other connectors from ${getUserAccountName(userState)}`
        : connectors.length === 1
          ? ` is a potential connector from ${getUserAccountName(userState)}`
          : ` are potential connectors from ${getUserAccountName(userState)}`;
    return (
      <>
        <b>{top3.join(', ')}</b>
        {additional}
      </>
    );
  };

  const currentSamePortcoEmployee = () => _.get(target, 'currentSamePortcoEmployee', false);
  const pastSamePortcoEmployee = () => !currentSamePortcoEmployee() && _.get(target, 'hasSameAccountPosition', false);

  const isPortcoEmployee = () => _.get(target, 'account_type') === 'Portfolio';
  const getBestScore = () => {
    const bestScore = _.get(connectors, '0.chance_to_connect_to_display');
    return {
      score: bestScore,
      count: connectors.filter(({ chance_to_connect_to_display }) => chance_to_connect_to_display === bestScore).length,
    };
  };

  useEffect(() => {
    if (
      !mode ||
      loadingEntityUrn ||
      (mode === 'entityUrn' && !entityUrn) ||
      (mode === 'publicIdentifier' && !publicIdentifier)
    ) {
      return;
    }
    const fetchResults = async () => {
      try {
        setLoading(true);
        const res = await get_portco_connectors_mini(
          { public_identifier: publicIdentifier, entityUrn, source, fullName },
          api,
        );
        setConnectors(res.connectors);
        setTarget(res.target);
      } finally {
        setLoading(false);
      }
    };
    fetchResults();
  }, [mode, publicIdentifier, entityUrn, loadingEntityUrn, api, fullName]);

  return (
    <>
      {is_talpod(userState) && <ImpersonateController source={'extension'} />}
      {is_focus(userState) && (
        <div
          style={{
            overflow: 'hidden',
            fontFamily: 'Inter',
            fontWeight: 500,
            fontSize: '13px',
            backgroundColor: 'transparent',
            width: '100%',
            color: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            alignItems: 'center',
            height: '35px',
          }}
        >
          <span
            style={{
              marginRight: '16px',
              position: 'relative',
            }}
          >
            {is_fake(userState) ? (
              <Tooltip title={'remove fake user'} placement={'right'}>
                <Link onClick={killFake}>
                  <PortcoFavicon portcoName={getUserAccountName(userState)} width={28} height={28} />
                </Link>
              </Tooltip>
            ) : (
              <PortcoFavicon portcoName={getUserAccountName(userState)} width={28} height={28} />
            )}

            <div
              style={{
                position: 'absolute',
                bottom: '8px',
                left: '20px',
              }}
            >
              {!loading &&
                !_.isUndefined(target) &&
                !isPortcoEmployee() &&
                !pastSamePortcoEmployee() &&
                _.get(getBestScore(), 'count') !== 0 && <ConnectorScoreMini {...getBestScore()} />}
            </div>
          </span>
          {loading && <img src={loadingAnimation} style={{ width: '27px', height: '27px' }} alt={''} />}
          {!loading && (
            <>
              {!showFeedback && (
                <>
                  {!_.isEmpty(connectors) && !isPortcoEmployee() && (
                    <Link
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      href={geFocusPageUrl({ public_identifier: publicIdentifier, entityUrn, source, fullName })}
                      target={'_blank'}
                      style={{ display: 'inline', color: hover ? '#0A66C2' : 'rgba(0,0,0,0.6)' }}
                    >
                      {ConnectorsText()}
                    </Link>
                  )}
                  {_.isUndefined(target) && (
                    <>
                      {requested ? (
                        <span style={{ display: 'inline' }}>
                          {' '}
                          We will search for {fullName}'s connections. Check again in 48 hours.{' '}
                        </span>
                      ) : (
                        <span style={{ display: 'inline' }}>
                          {' '}
                          Want us to check {fullName}'s connections?{' '}
                          <Link onClick={checkMissing} style={{ color: '#0073B2' }}>
                            Submit Request
                          </Link>{' '}
                        </span>
                      )}
                    </>
                  )}
                  {!_.isUndefined(target) &&
                    _.isEmpty(connectors) &&
                    !isPortcoEmployee() &&
                    !_.get(target, 'hasSameAccountPosition', false) && (
                      <span style={{ display: 'inline' }}>
                        Couldn't find potential connectors from {getUserAccountName(userState)} - Did we get it wrong?{' '}
                        <Link style={{ color: '#0073B2' }} onClick={toggleFeedback}>
                          Submit Feedback
                        </Link>
                      </span>
                    )}
                  {currentSamePortcoEmployee() && (
                    <span style={{ display: 'inline' }}>
                      Seems like {_.get(target, 'FirstName')} is currently working at {getUserAccountName(userState)} -
                      Did we get it wrong?{' '}
                      <Link style={{ color: '#0073B2' }} onClick={toggleFeedback}>
                        Submit Feedback
                      </Link>
                    </span>
                  )}
                  {pastSamePortcoEmployee() && (
                    <span style={{ display: 'inline' }}>
                      Seems like {_.get(target, 'FirstName')} used to be {getUserAccountName(userState)} employee - Did
                      we get it wrong?{' '}
                      <Link style={{ color: '#0073B2' }} onClick={toggleFeedback}>
                        Submit Feedback
                      </Link>
                    </span>
                  )}
                  {isPortcoEmployee() && !currentSamePortcoEmployee() && !pastSamePortcoEmployee() && (
                    <span style={{ display: 'inline' }}>
                      Seems like {_.get(target, 'FirstName')} is currently working at an Aleph portfolio company - Did
                      we get it wrong?{' '}
                      <Link style={{ color: '#0073B2' }} onClick={toggleFeedback}>
                        Submit Feedback
                      </Link>
                    </span>
                  )}
                </>
              )}
              {showFeedback && (
                <UserFeedback
                  fullName={fullName}
                  public_identifier={publicIdentifier}
                  placeholder={'Write connector name or anything else we need to know'}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export const NoFocusForYou = () => {
  return <></>;
};

export const FocusMini = withUserState(_FocusMini);
