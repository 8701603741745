import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  Select,
  SvgIcon,
  Tooltip,
} from '@material-ui/core';
import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { ReactComponent as LinkedIn } from '../../static/linkedin-blue.svg';
import { ReactComponent as salesforceIcon } from '../../static/salesforce-white.svg';
import { is_admin, is_defpod, is_funding, is_salespod, is_talpod, useUserState } from '../../userContext';
import { assignGroup } from '../../utils/connectors';
import { getRamzorScoreText, RamzorIcon } from '../../utils/contacts';
import { getDateRangeText } from '../../utils/dates';
import {
  ConnectorsFilters,
  createInitialFiltersByTargetContact,
  createInitialFiltersByTargetContactForDefpod,
  createInitialFiltersByTargetContactForSales,
  createOptionalFiltersTargetContact,
  employeeFilterer,
  getAllConnectors,
} from '../utils/connectors-filters';
import { Referrers } from '../utils/referrers';

export function getDefaultGoalByUser(user) {
  if (is_talpod(user)) {
    return 'Talent';
  }
  if (is_salespod(user)) {
    return 'Sales Opportunity';
  }
  if (is_funding(user)) {
    return 'Follow-on Funding';
  }
  return 'Other';
}

const Role = ({ r, i, showAllRoles, toggleRoles }) => {
  const { Start_Date__c, End_Date__c, Account_Name__c, role_title, Id } = r;
  return (
    <ListItem key={'role-' + Id} dense style={{ marginBottom: '6px', paddingLeft: '0px' }}>
      <div
        style={{
          fontFamily: 'Merriweather Sans',
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '20px',
        }}
      >
        {getDateRangeText({ Start_Date__c, End_Date__c })}
        <span style={{ marginRight: '5px', marginLeft: '5px', fontWeight: 300, color: '#7E7E7E', fontSize: '11px' }}>
          {' '}
          &#x25CF;{' '}
        </span>
        @{Account_Name__c}
        <span style={{ marginRight: '5px', marginLeft: '5px', fontWeight: 300, color: '#7E7E7E', fontSize: '11px' }}>
          {' '}
          &#x25CF;{' '}
        </span>
        {role_title}
        {_.get(r, 'Soft_Deleted__c') ? <span> (Soft Deleted) </span> : ''}
        {i === 0 && (
          <>
            <span
              style={{ marginRight: '5px', marginLeft: '5px', fontWeight: 300, color: '#7E7E7E', fontSize: '11px' }}
            >
              {' '}
              &#x25CF;{' '}
            </span>
            <Link onClick={toggleRoles}>
              {showAllRoles ? 'Hide work history' : 'Show work history'}{' '}
              {showAllRoles ? (
                <ArrowDropDown fontSize="small" style={{ verticalAlign: 'middle' }} />
              ) : (
                <ArrowRight fontSize="small" style={{ verticalAlign: 'middle' }} />
              )}
            </Link>
          </>
        )}
      </div>
    </ListItem>
  );
};

const getRolesToShow = (target, isAdmin) => {
  if (isAdmin) {
    return _(target.roles)
      .filter(
        (r) =>
          (!_.get(r, 'Soft_Deleted__c') || isAdmin) &&
          _.includes(['Executive', 'Manager', 'Employee', 'Founder'], _.get(r, 'Role__c')),
      )
      .orderBy(['Is_Direct__c', 'Start_Date__c', 'Soft_Deleted__c'], ['desc', 'desc', 'asc'])
      .uniqBy(({ Start_Date__c, End_Date__c, Account_Name__c }) => Start_Date__c + End_Date__c + Account_Name__c)
      .value();
  } else {
    return _.orderBy(target.pgPositions, ['Start_Date__c'], ['desc']);
  }
};

const EmploymentHistory = ({ target }) => {
  const { userState } = useUserState();
  const [showAllRoles, setShowRoles] = useState(false);
  const toggleRoles = () => setShowRoles(!showAllRoles);
  const rolesToShow = getRolesToShow(target, is_admin(userState));

  return (
    <div>
      {!_.isEmpty(rolesToShow) && (
        <>
          <List style={{ padding: '0' }}>
            {(showAllRoles ? rolesToShow : _.take(rolesToShow, 1)).map((r, i) => (
              <Role r={r} key={'role-container' + r.Id} i={i} showAllRoles={showAllRoles} toggleRoles={toggleRoles} />
            ))}
          </List>
        </>
      )}
    </div>
  );
};

const PoscanSelect = ({ poscans, selectedPoscan, setSelectedPoscan }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 35,
      },
    },
  };

  return (
    <FormControl style={{ width: '350px' }}>
      <InputLabel id="poscan-select-label">Poscan</InputLabel>
      <Select
        labelId="poscan-select-label1"
        id="poscan-select"
        value={selectedPoscan}
        onChange={(event) => setSelectedPoscan(event.target.value)}
        input={<Input />}
        MenuProps={MenuProps}
      >
        {poscans.map((p) => (
          <MenuItem key={p.Id} value={p}>
            {p.Position_Title__c} - {p.Account__c}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const isOrphan = (target) =>
  _.isEmpty(_.compact(getAllConnectors(target))) &&
  _.get(target, 'chanceToConnect', 'Low likelihood') === 'Low likelihood';

const TargetHeadline = ({ target, selectedPoscan, setSelectedPoscan }) => {
  const { userState } = useUserState();
  return (
    <div style={{ marginBottom: '45px' }}>
      <div>
        <span style={{ fontFamily: 'Merriweather Sans', fontWeight: 700, fontSize: '24px', marginRight: '4px' }}>
          {_.get(target, 'name')}
        </span>
        {is_admin(userState) && !!_.get(target, 'Id') && (
          <Link
            href={`https://aleph.lightning.force.com/lightning/r/Contact/${_.get(target, 'Id')}/view`}
            target="_blank"
          >
            <SvgIcon
              component={salesforceIcon}
              style={{ width: 25, height: 20, marginRight: '4px' }}
              viewBox={'0 0 8 10'}
            />
          </Link>
        )}
        <Link
          href={_.get(target, 'linkedin_profile') + `${is_admin(userState) ? '?ampliphy_update=true' : ''}`}
          target="_blank"
        >
          <SvgIcon component={LinkedIn} style={{ width: 16, height: 16, marginRight: '10px' }} viewBox={'0 0 16 16'} />
        </Link>
        {is_talpod(userState) && !!_.get(target, 'ramzorExplanation') && (
          <Tooltip title={_.get(target, 'ramzorExplanation') || ''}>
            <RamzorIcon c={target} size={20} />
          </Tooltip>
        )}
        {(!is_talpod(userState) || !_.get(target, 'ramzorExplanation')) && <RamzorIcon c={target} size={20} />}
        <span
          style={{
            fontFamily: 'Merriweather Sans',
            fontWeight: 300,
            fontSize: '14px',
            color: '#4D4D4D',
            marginLeft: '8px',
          }}
        >
          {getRamzorScoreText(target)}
        </span>
        {is_salespod(userState) && (
          <span
            style={{
              fontFamily: 'Merriweather Sans',
              fontWeight: 300,
              fontSize: '14px',
              color: '#4D4D4D',
              marginLeft: '8px',
            }}
          >
            | {target.connections_count} connections | {target.activities_count} activities
          </span>
        )}
        {!_.isEmpty(selectedPoscan) && (
          <div
            style={{
              fontFamily: 'Merriweather Sans',
              fontWeight: 300,
              fontSize: '14px',
              lineHeight: '20px',
              marginTop: '10px',
            }}
          >
            There is an open {_.lowerCase(selectedPoscan.Request_Type__c)} for
            <b>
              {` ${selectedPoscan.Position_Title__c} ${is_admin(userState) ? `(${selectedPoscan.Account__c})` : ''}`}{' '}
            </b>{' '}
            position. For more details see{' '}
            <Link href={'#/talent/candidates'} target={'_blank'}>
              Status
            </Link>
          </div>
        )}
        {is_admin(userState) && _.get(target, 'poscans', []).length > 1 && (
          <>
            <br />
            <br />{' '}
            <PoscanSelect
              poscans={_.get(target, 'poscans', [])}
              selectedPoscan={selectedPoscan}
              setSelectedPoscan={setSelectedPoscan}
            />
          </>
        )}
      </div>
      {!_.isEmpty(target) && (!_.isEmpty(target.roles) || !_.isEmpty(target.pgPositions)) && (
        <EmploymentHistory target={target} />
      )}
      {isOrphan(target) && (
        <div
          style={{
            fontFamily: 'Merriweather Sans',
            fontWeight: 300,
            fontSize: '14px',
            lineHeight: '20px',
            marginTop: '10px',
          }}
        >
          We couldn't automatically find potential connectors. Please submit a request so we can check more deeply and
          get back to you
        </div>
      )}
    </div>
  );
};

export const Target = ({
  target: rawTarget,
  alephUsers,
  selected_poscan_requests,
  connectorsMap,
  setSelectedReferrersSet,
  setSelectedReferrers,
  toggle_poscan_request,
  selectedReferrersSet,
}) => {
  const { userState } = useUserState();
  const [target, setTarget] = useState();
  const [selectedPoscan, setSelectedPoscan] = useState();

  useEffect(() => {
    const firstPoscan = _.get(rawTarget, 'poscans.0');
    setSelectedPoscan(firstPoscan);
    const [relColleagues, portcoColleagues] = _.partition(
      _.get(rawTarget, 'pastColleagues', []),
      (c) => assignGroup({ c, selectedPoscan, userState }) === 'Network',
    );
    setTarget({
      ...rawTarget,
      linkedin_profile: `https://www.linkedin.com/in/${rawTarget.public_identifier}`,
      relationshipColleaguesCount: relColleagues.length,
      portcoColleaguesCount: portcoColleagues.length,
    });
  }, [rawTarget, selectedPoscan, userState]);

  const toggleReferrer =
    ({ referrerUrl, targetPublicId, ask }) =>
    () => {
      const refString = JSON.stringify({ referrerUrl, targetPublicId, ask });
      if (selectedReferrersSet.has(refString)) {
        setSelectedReferrersSet(selectedReferrersSet.remove(refString));
      } else {
        setSelectedReferrersSet(selectedReferrersSet.add(refString));
      }
    };

  useEffect(() => {
    if (selectedReferrersSet.isEmpty()) {
      setSelectedReferrers([]);
      return;
    }
    const tempReferrers = [];
    selectedReferrersSet.forEach((pid) => {
      const { referrerUrl, targetPublicId, ask } = JSON.parse(pid);
      const referrer = connectorsMap[referrerUrl];
      tempReferrers.push({ referrer, targetPublicId, ask });
    });
    setSelectedReferrers(tempReferrers);
  }, [connectorsMap, selectedReferrersSet, setSelectedReferrers]);

  const [introGoal, setIntroGoal] = useState(getDefaultGoalByUser(userState));

  const [optionalConnectorFilters, setOptionalConnectorFilters] = useState();
  const [connectorFilters, setConnectorFilters] = useState({});

  const [filteredReferrers, setFilteredReferrers] = useState([]);
  // const [filteredPastColleagues, setFilteredPastColleagues] = useState([])

  /*
    useEffect(()=>{
        setFilteredPastColleagues(
            filteredReferrers
                .filter(({ roles })=>!_.isUndefined(roles) && roles.find(({ overlappingMonths })=>overlappingMonths>0))
        )
    }, [filteredReferrers])
    */

  useEffect(() => {
    function getFilteredConnectors() {
      const tempFiltered = employeeFilterer({
        connectorFilters,
        potentialConnectors: getAllConnectors(target),
      });
      const min_num_of_employees = (c) =>
        _.min((c.roles || [{ NumberOfEmployees: '0' }]).map((r) => _.toNumber(_.get(r, 'NumberOfEmployees', 0))));
      const just_a_linkedin_connection = (c) => {
        const non_linkedin_signals = c.signals.filter((s) => s.Type__c !== 'Linkedin Connection');
        return _.isEmpty(c.roles) && _.isEmpty(non_linkedin_signals) && c.connectionOnLinkedin;
      };
      const just_a_linkedin_comment = (c) => {
        const non_linkedin_comment_signals = c.signals.filter(
          (s) => s.Type__c !== 'Linkedin Comment' && s.Type__c !== 'Linkedin Connection',
        );
        const linkedin_comment_signals = c.signals.filter((s) => s.Type__c === 'Linkedin Comment');
        return _.isEmpty(c.roles) && _.isEmpty(non_linkedin_comment_signals) && !_.isEmpty(linkedin_comment_signals);
      };
      function connectorFilter(c) {
        if (
          !is_admin(userState) &&
          (c.announcement_status === 'Confidential / Stealth Mode' ||
            assignGroup({ c, selectedPoscan, userState }) === 'Network')
        ) {
          return false;
        }
        if (!connectorFilters.showCurrentColleagues && c.current_account_id_of_contact === target.AccountId) {
          return false;
        }
        if (c.score < connectorFilters.min_relationship) {
          return false;
        }
        if (!connectorFilters.showJustLinkedin && just_a_linkedin_connection(c)) {
          return false;
        }
        if (!connectorFilters.showLinkedinComments && just_a_linkedin_comment(c)) {
          return false;
        }
        if (
          (connectorFilters.linkedinConnection === 'Only Connections' ||
            (connectorFilters.linkedinConnection === '#Employees < 1000' && min_num_of_employees(c) > 1000) ||
            (connectorFilters.linkedinConnection === '#Employees < 500' && min_num_of_employees(c) > 500) ||
            (connectorFilters.linkedinConnection === '#Employees < 200' && min_num_of_employees(c) > 200) ||
            (connectorFilters.linkedinConnection === '#Employees < 100' && min_num_of_employees(c) > 100)) &&
          !(c.connectionOnLinkedin || !_.isEmpty(c.signals))
        ) {
          return false;
        } else {
          return true;
        }
      }

      const AlwaysShow = getAllConnectors(target).filter(
        (c) => assignGroup({ c, selectedPoscan, userState }) === 'Same Account',
      );

      return _(tempFiltered)
        .filter(connectorFilter)
        .concat(AlwaysShow)
        .orderBy(
          is_defpod(userState) ? ['defpodScore', 'score'] : ['talpodScore'],
          is_defpod(userState) ? ['desc', 'desc'] : ['desc'],
        )
        .uniqBy('Contact__c')
        .value();
    }

    if (_.isEmpty(target) || _.isEmpty(connectorFilters)) {
      return;
    }
    setFilteredReferrers(getFilteredConnectors());
  }, [connectorFilters, selectedPoscan, target, userState]);

  const default_filters = is_salespod(userState)
    ? createInitialFiltersByTargetContactForSales
    : is_defpod(userState)
      ? createInitialFiltersByTargetContactForDefpod
      : createInitialFiltersByTargetContact;

  useEffect(() => {
    if (!_.isEmpty(target)) {
      setOptionalConnectorFilters(createOptionalFiltersTargetContact(target));
      setConnectorFilters(default_filters(getAllConnectors(target)));
    }
  }, [default_filters, target]);

  const gridWidth = 11;

  const isRefCheckRequest = (public_identifier) =>
    selected_poscan_requests.has(
      JSON.stringify({
        targetPublicId: public_identifier,
        ask: 'Reference Check',
      }),
    );

  return (
    <>
      {!_.isEmpty(target) && (
        <>
          <Grid container>
            <Grid item xs={gridWidth} sm={gridWidth} md={gridWidth} xl={gridWidth} lg={gridWidth}>
              <TargetHeadline target={target} selectedPoscan={selectedPoscan} setSelectedPoscan={setSelectedPoscan} />
              {is_admin(userState) && !isOrphan(target) && !_.isEmpty(connectorFilters) && (
                <ConnectorsFilters
                  connectorFilters={connectorFilters}
                  setConnectorFilters={setConnectorFilters}
                  optionalConnectorFilters={optionalConnectorFilters}
                  setFilteredReferrers={setFilteredReferrers}
                  totalCount={getAllConnectors(target).length}
                  selectedCount={filteredReferrers.length}
                  target={target}
                  introGoal={introGoal}
                  setIntroGoal={setIntroGoal}
                />
              )}
            </Grid>
          </Grid>
          <Referrers
            filteredReferrers={filteredReferrers}
            target={target}
            open={isRefCheckRequest(target.public_identifier) || is_admin(userState)}
            key={'colleagues-' + target.id}
            toggleReferrer={toggleReferrer}
            connectorFilters={connectorFilters}
            introGoal={introGoal}
            selectedReferrersSet={selectedReferrersSet}
            selected_poscan_requests={selected_poscan_requests}
            alephUsers={alephUsers}
            selectedPoscan={selectedPoscan}
          />
        </>
      )}
    </>
  );
};
