export async function get_portco_connectors_mini({ public_identifier, entityUrn, source, fullName }, api) {
  const params = {};
  if (public_identifier) {
    params.public_identifier = encodeURIComponent(public_identifier);
  }
  if (entityUrn) {
    params.entityUrn = encodeURIComponent(entityUrn);
  }
  if (source) {
    params.source = source;
  }
  if (fullName) {
    params.fullName = encodeURIComponent(fullName);
  }
  const queryString = new URLSearchParams(params).toString();
  return api(`focus/get_portco_connectors_mini/?${queryString}`, { method: 'GET' });
}

export async function get_portco_connectors_full({ public_identifier, entityUrn, source, post_creation }, api) {
  const params = {};
  if (public_identifier) {
    params.public_identifier = encodeURIComponent(public_identifier);
  }
  if (entityUrn) {
    params.entityUrn = encodeURIComponent(entityUrn);
  }
  if (source) {
    params.source = source;
  }
  if (post_creation) {
    params.post_creation = post_creation;
  }
  const queryString = new URLSearchParams(params).toString();
  return api(`focus/get_portco_connectors_full/?${queryString}`, { method: 'GET' });
}

export async function public_identifier_to_entity_urn({ public_identifier }, api) {
  return api(`focus/public_identifier_to_entity_urn/?public_identifier=${encodeURIComponent(public_identifier)}`, {
    method: 'GET',
  });
}

export async function check_missing_profile({ public_identifier, fullName }, api) {
  return api(
    `focus/check_missing_profile/?public_identifier=${encodeURIComponent(public_identifier)}&fullName=${encodeURIComponent(fullName)}`,
    { method: 'GET' },
  );
}

export async function read_slack_user_info({ slack_id, portco_name }, api) {
  return api(
    `focus/read_slack_user_info/?slack_id=${encodeURIComponent(slack_id)}&portco_name=${encodeURIComponent(portco_name)}`,
    { method: 'GET' },
  );
}

export async function handle_focus_list({ leads, desiredChanceToConnect }, api) {
  return api('focus/handle_focus_list/', { method: 'POST', body: JSON.stringify({ leads, desiredChanceToConnect }) });
}

export async function send_focus_mini_feedback({ public_identifier, comments, fullName }, api) {
  return api('focus/send_mini_feedback/', {
    method: 'POST',
    body: JSON.stringify({ public_identifier, comments, fullName }),
  });
}

export async function mark_relevance({ connector, target, relevance, reachoutId, customMessage, sendNow }, api) {
  return api('focus/mark_relevance/', {
    method: 'POST',
    body: JSON.stringify({ connector, target, relevance, reachoutId, customMessage, sendNow }),
  });
}

export async function submit_connector_feedback({ connector, target, comments }, api) {
  return api('focus/submit_connector_feedback/', {
    method: 'POST',
    body: JSON.stringify({ connector, target, comments }),
  });
}

export async function submit_slack_member_id({ slackMemberId, publicId }, api) {
  return api('focus/submit_slack_member_id/', { method: 'POST', body: JSON.stringify({ slackMemberId, publicId }) });
}
